import React, { FunctionComponent } from 'react';
import { configureRoute } from '../../../utils/routes/configure-route';
import { Carousel } from '../../components/carousel/carousel.component';
import { DefaultCarouselCard } from '../../components/carousel-components/default-carousel-card/default-carousel-card.component';
import { Link } from '../../components/common-components/link/link.component';
import { StyledLink } from '../../components/common-components/link/styled-link.component';
import { PageContainer } from '../../components/common-components/page/page-container/page-container.component';
import { CloudinaryImage } from '../../components/images/cloudinary-image/cloudinary-image.component';
import { FergusonBklLogoWithViewBoxSvg } from '../../components/showroom-components/ferguson-bkl-logo-with-viewbox/ferguson-bkl-logo-with-viewbox.component';
import { FindNearbyLocations } from '../../components/showroom-components/find-nearby-locations/find-nearby-locations.component';
import {
	PROJECT_TOOL_LINK,
	SHOP_BY_LOOK_LINK,
	SHOWROOM_LINK,
	SHOWROOM_SEARCH_LINK,
	SSO_LOGIN_LINK,
	SSO_LOGOUT_LINK
} from '../../constants/links';
import { CarouselItem } from '../../helpers/carousel/carousel.helper';
import { useTrackPageView } from '../../hooks/analytics/analytics.hooks';
import { useCustomer } from '../../hooks/apollo/customer/customer.hooks';
import { useEmployee } from '../../hooks/apollo/employee/employee.hooks';
import { useNavigation } from '../../hooks/navigation/navigation.hooks';
import { prepForApptSection, splash } from './showroom.css';

export const Showroom: FunctionComponent = () => {
	const pageName = 'landing_pages:showroom_landing';
	const showroomDataLayer = {
		page: pageName,
		isShowroom: true
	};

	const navigate = useNavigation();

	useTrackPageView({ pageName }, showroomDataLayer);

	const { employee } = useEmployee();
	const isAuthenticatedEmployee = employee?.isAuthenticated;

	const { data: customerData } = useCustomer();
	const customerLocation = customerData?.customer.location;
	const userZipcode = customerLocation?.zipCode;

	const imageUrls = [
		'ferguson-location_0594-washington-dc_dsc-3780.jpg',
		'ferguson-locations_2017_0234-naples-fl_041-65-bathroom-and-lighting.jpg',
		'ferguson-locations_2019_0245-austin-tx_009a6297-editedb.jpg',
		'ferguson-locations_2019_0245-austin-tx_009a6355-edited.jpg',
		'fergusonshowroom047.jpg',
		'fergusonshowroom127.jpg',
		'showroom_2015_newport-news_entrance.jpg',
		'showroom_2015_newport-news_faucet-wall-final.jpg',
		'showroom_2016_newport-news_2162513-10361.jpg',
		'showroom_2016_newport-news_2162513-20032.jpg',
		'showroom_2020_annual-report_ox0825ok01-149h.jpg',
		'shw_2019_taste-highland-park_19-194--155-.jpg'
	];

	const carouselItems: CarouselItem[] = imageUrls.map((imageUrl, i) => ({
		description: `item ${i + 1}`,
		id: `mediabase/site/build.com/images/landing-pages/showroom/carousel/${imageUrl}`,
		imageType: 'private'
	}));

	const onLocationSearch = (input: string): void => {
		const searchByLocationLink = `${SHOWROOM_SEARCH_LINK}?searchTerm=${input}`;
		navigate(searchByLocationLink);
	};

	return (
		<>
			<section className={`${splash} relative flex justify-center`}>
				<div className="absolute bg-theme-white-o80 w-80 w-60-ns h-100" />
				<div className="relative flex flex-column justify-center items-center tc w-100">
					<div className="flex justify-center w-70 mt3-ns" style={{ height: '74px' }}>
						<FergusonBklLogoWithViewBoxSvg className="h-100" />
					</div>
					<div className="bg-theme-white theme-grey normal w-100 w-auto-ns pa3 ma3 f3 f2-l lh-title shadow-4 ">
						CREATE A SPACE YOU LOVE TO LIVE IN
					</div>
					<div className="w-70 w-60-ns">
						<div className="fw6 f6 f4-l lh-copy mt3">Find your local showroom to book an appointment.</div>
						<div className="dib pa4">
							<FindNearbyLocations
								onSuccessSubmit={onLocationSearch}
								userZipcode={userZipcode}
								showUseCurrentLocation={false}
							/>
						</div>
					</div>
				</div>
			</section>
			<PageContainer
				pageTitle="Ferguson Kitchen & Bath Showroom Locations"
				canonicalURL="/showroom"
				metaDescription="Find your local Ferguson Bath, Kitchen & Lighting Gallery. Our showroom offers premium products for your next renovation or build.">
				<>
					<div className="flex flex-column items-center pa0 pa3-ns">
						<div className="tc f6 f4-l w-80-ns lh-copy">
							<h1 className="ma3 f3 f2-l lh-solid">Your Showroom Experience</h1>
							Benefit from a personalized one-on-one consultation with our experts to review every detail of your project, and
							receive hands-on experience with a variety of stunning products in the process.
						</div>
						<div className="flex flex-wrap w-100 mt6 f5 f4-l lh-copy">
							<section className={`bg-theme-grey-lighter pa5 pa6-ns nl3 nr3 mh0-ns ${prepForApptSection}`}>
								<h2 className="f3 f2-l lh-title mt0 mb2">Preparing for Your Appointment</h2>
								Before scheduling your personalized consultation, take some time to gather the following:
								<h3 className="f5 f4-ns lh-title mb0">Inspiration</h3>
								What styles, colors and products are you drawn to? <Link url={SHOP_BY_LOOK_LINK}>Shop by Look </Link>to view
								curated projects and save your favorites.
								<h3 className="f5 f4-ns lh-title mb0">Partners</h3>
								Let us know if you’re tackling this project on your own or if you’ve already started working with other
								professionals.
								<h3 className="f5 f4-ns lh-title mb0">Details</h3>
								Collect plans, blueprints, measurements and more so we can help you pick out the perfect products.
								<h3 className="f5 f4-ns lh-title mb0">Budget</h3>
								To make sure we stay on track, it’s important to understand your established budget, goals and expectations.
								<div className="mt5">
									<FindNearbyLocations
										className={'dib'}
										onSuccessSubmit={onLocationSearch}
										userZipcode={userZipcode}
										showUseCurrentLocation={false}
									/>
								</div>
							</section>
							<div className="w-100 w-30-ns flex flex-column pa3 pa0-ns pl5-ns">
								<CloudinaryImage
									publicID="content-tool/thckqi0bvjehimxrpi4q"
									options={{ width: 250, height: 211 }}
									useDimensions={false}
									description=""
									className="w-70 ph4 self-center"
								/>

								<h3 className="f5 lh-title">Need help getting organized?</h3>
								<div>
									Use our free Build with Ferguson <StyledLink url={PROJECT_TOOL_LINK}>Project Tool</StyledLink> to save
									your favorite products and organize them by project or room.
								</div>

								<hr style={{ height: '1px' }} className="w-100 mt5 bn bg-theme-grey-light dn-ns" />
							</div>
						</div>
						<div className="w-100">
							<div className="mt5-ns pt3 pt0-ns mb3-ns mb2">
								<div className="flex flex-row items-center justify-center">
									<span className="flex fw7 f3 f2-l lh-title-ns lh-solid w-100 justify-center">
										Explore Exquisite Showrooms
									</span>
								</div>
							</div>
							<div className="mt3-ns mt1">
								<div className="ba-ns b--theme-grey-light bg-theme-grey-lighter">
									<Carousel shouldLockNavigation>
										<div className="flex flex-shrink-0 gc3">
											{carouselItems.map((item, index) => (
												<DefaultCarouselCard
													item={item}
													cloudinaryOptions={{ width: 289, height: 194 }}
													ariaLabel="Exquisite Showroom Image"
													key={`${item.id}-${index}`}
												/>
											))}
										</div>
									</Carousel>
								</div>
							</div>
						</div>
					</div>
					<div className="f7 ma3">
						{isAuthenticatedEmployee ? (
							<>
								{employee.fullName}:
								<StyledLink underline url={SSO_LOGOUT_LINK} className="ml1" color="grey-darker" underlineHover={true}>
									Logout
								</StyledLink>
							</>
						) : (
							<StyledLink url={SSO_LOGIN_LINK} color="grey-darker" underlineHover={true}>
								Employee Login
							</StyledLink>
						)}
					</div>
				</>
			</PageContainer>
		</>
	);
};

export const RoutedShowroom = configureRoute({ path: SHOWROOM_LINK, exact: true, DANGEROUSLY_IS_LIVE: true }, Showroom);
